.reports__container{
    background-color: rgb(236,236,236);
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100svh;
}

.reports__title__db{
    height:5svh;
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    gap: 10px;
}

.reports__title__db i{
    padding: 1rem;
    cursor: pointer;
}

.reports__title__db{
    position: relative;
}

/* REPORT GRID CHART CSS */

.report__gridchart__container{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.report__gridchart__content{
    background-color: var(--bkg__primary);
    padding: 1rem;
    box-shadow: var(--shdw);
    height: 600px;
    width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.report__vegetation__chart__conntainer{
    flex: 1;
}

.report__gridchart__actions{
    display: flex;
    justify-content: flex-end;
}