.intervention__container{
    background-color: rgb(236,236,236);
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100svh;
    position: relative;
}

.intervention__title__db{
    height:5svh;
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    gap: 10px;
}

.intervention__title__db i{
    padding: 1rem;
    cursor: pointer;
}

.intervention__title__db{
    position: relative;
}