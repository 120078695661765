.intervention__modal__container{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.intervention__modal__content{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: var(--bkg__primary);
    box-shadow: var(--shdw);    
    padding: 1.5rem;
    flex-direction: column;
    gap: 1rem;
}

.intervention__modal__content h4 {
    padding-bottom: 0.5rem;
}

.intervention__modal__actions{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    gap: 0.5rem;
}

.intervention__modal__inputs{
    display: flex;
    flex-direction: column;
    gap: 0.8rem
}