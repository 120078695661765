.weather__history__container{
    height: 100svh;
    flex: 1;
    background-color: rgb(236,236,236);
}

.history__contents{
    margin: 0 2rem;
    display: flex;
    flex-direction: column;
}

.history__contents a{
    color: gray;
    padding: 1rem;
}

.history__header{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.3rem;
}

.history__body{
    flex: 1;
    padding: 0 1rem;
}


.history__body table{
    background-color: rgb(236, 236, 236);
    border-collapse: collapse;
    width: 50%;
}

.history__body table tr th{
    padding: 0.6rem;
    border: 1px solid gray;
    font-size: 12px;
}