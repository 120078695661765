.farmView-container{
    flex: 1;
    position:  relative;
    display: flex;
}

.leaflet-container{
    position: relative;
    height: 100%;
    width: 100%;
}

#map{
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100svh;
}


/* Side menu bar farm view */

.farmview__side__menubar{
    flex: 1 1 400px;
    z-index: 100;
}

.map__top__container{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10000;
    width: 100%;
    align-items: center;
}

.map__top__content{
    padding: 1.5rem;
    display: flex;
    gap: 5px;
}

.back__link__db{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.menu__layers{
    width: 90%;
    display: flex;
    margin: 0 1rem;
}

.menu__hamburger{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 4px;
}

.menu__hamburger button{
    border: none;
    font-size: 25px;
    background: transparent;
}

.menu__hamburger button{
    cursor: pointer;
}

.legend__container{
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10000;
    align-items: center;;
    padding: 0.8rem;
}

.maps__config{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10000;
    align-items: center;
}

.farm__view__loader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.draw__polygon__actions{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 30px;
    padding: 1rem;
}

.maps__cardinal__directions{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 500;
}

.maps__cardinal__directions img{
    width: 80px;
    padding: 1rem;
}




@media only screen and (max-width: 768px){
    .farmview__side__menubar{
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        z-index: 1300;
    }

    .farmView-container{
        height: 100svh;
    }

    #map{
        height: 100svh;
    }
    
.maps__config{
    position: absolute;
    top: 60px;
    right: 0;
    z-index: 10000;
    align-items: center;
}
}