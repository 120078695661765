

.about-container{
    background-color: rgb(246,246,246);
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    width: 100%;
}

.about-title{
    width: 100%;
    text-align: center;
    background: url('../../assets/banana-about.webp');
    object-fit: cover;
    background-position: center;
    height: 25vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-title h1{
    color: white;
}

.about-content{
    padding: 2rem 7rem;
    margin: 0 2rem; 
    line-height: 40px;
}

.about-content p{
    color: black;
    text-align: start;
    font-size: larger;
}

.team-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(225,225,225);
}

.team-container h1{
    padding: 1rem;
    margin: 1rem 0;
}

.card-team-container{
    width: 100%;
}

.card-holder{
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    padding: 0 5rem;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-bottom: 2.5rem;
}

.card{
    background-color: rgb(236,236,236);
    border-radius: 12px;
    text-align: center;
    padding: 1rem;
    width: 200px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card img{
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.card-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
}

@media only screen and (max-width: 768px){
    .about-container{   
        height: auto;
        
    }

    .about-title{
        height: 20vh;
    }

    .about-title h1{
        font-size: 22px;
    }

    .about-content{
        width: 100%;
        margin: 0 1rem;
        line-height: 25px;
    }

    .about-content p{
        text-align: center;
        font-size: 15px;
        padding: 1rem 2rem;
    }

    .team-container h1{
        margin-top: 1.5rem;
        font-size: 18px;
    }

    .card-holder{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 1rem;
    }

    
    .card{
        background-color: rgb(236,236,236);
        border-radius: 12px;
        text-align: center;
        width: 150px;
    }
    
    .card img{
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }
    
    .card-info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 12px;
    }


}