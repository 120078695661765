.create-container{
    background-color: var(--bkg__whiteColor);
    height: 100svh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    position: relative;
}

.create-contents{
    background-color: var(--bkg__primary);
    padding: 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 280px;
    border-top: 3px solid var(--primary__color);
}

.create-contents h3{
    margin-bottom: 0.5rem;
    padding: 0.5rem;
}

.create-input-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    padding: 0.5rem;
}

.create-buttons{ 
    margin-top: 0.2rem;
    display: flex;
    justify-content: flex-end;
    gap: 0.3rem;
}

@media only screen and (max-width: 768px){
    .create-contents{
        width: 280px;
    }
}