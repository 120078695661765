.ndvi__container{
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1500;
}

.ndvi__header h4{
    flex: 1;
}

.ndvi__header{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1.5rem;
}


.ndvi__content{
    background-color: var(--bkg__primary);
    box-shadow: var(--shdw);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 700px;
}

.ndvi__content__inputs{
    flex: 1;
    display: flex;
    gap: 0.5rem;
}

.ndvi__actions{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/* table css */

.ndvi__table__container{
    width: 100%;
}

.ndvi__table{
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

.ndvi__table th{
    padding: 1rem;
    font-size: 14px;
}