::-webkit-scrollbar {
    width: 5px;
}

.farm__table__container::-webkit-scrollbar-thumb {
    background: #888;
}

.farm__table__container{
    width: 100%;
    max-height: 70svh;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: relative;
    border-bottom: 5px solid rgb(125,163,60);
}

.farm__table__container table{
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

.farm__table__container table th{
    background-color: var(--primary__color);
    padding: 1rem;
    color: white;
    position: sticky;
    top: 0;
}

.farm__table__container table td{
    font-size: 16px;
    text-transform: capitalize;
    padding: 0.2rem 0;
}

/* .farm__table__container table button, .tb__view, .tb__edit{
    outline: none;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 13px;
    padding: 0.5rem 0.5rem;
} */

.tb__actions{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

/* .farm__table__container table .tb__edit{
    color: white;
    background-color:  var(--bkg__edit);
    width: 50px;
    text-decoration: none;
    border-radius: 10px;
} */

/* .farm__table__container table .tb__view{
    color:white;
    text-decoration: none;
    background-color: var(--primary__color);
    width: 50px;
    border-radius: 10px;
} */

/* .farm__table__container table .tb__delete{
    color: white;
    background-color: red;
    width: 60px;
    border-radius: 10px;
} */

.farm__table__container table tbody tr:nth-of-type(even){
    background-color:#F2F2F2;
}

.farm__table__container table tbody tr td{
    padding: 0.4rem;
}

.farm__loading{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 60svh;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagenated__buttons{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 0.5rem;
    padding: 0.5rem 0;
}

.pagenated__buttons button{
    padding: 0.7rem;
    border: none;
    background-color: rgb(236,236,236);
}

.pagenated__buttons button:hover{
    cursor: pointer;
}

.records__warning{
    color: black;
}

@media only screen and (max-width: 768px){
    .tb__actions{
        display: flex;
        align-items: center;
        justify-content: center;
        gap:5px;
    }  

    .farm__table__container table td{
        font-size: 14px;
    }
    
}