.company__usersTable__container{
    width: 70%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: relative;
    border-bottom: 5px solid rgb(125,163,60);
}

.company__usersTable__container table{
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}

.company__usersTable__container table tr th{
    font-size: 13px;
}

.company__usersTable__container table tr td{
    padding: 0.6rem;
    font-size: 14px;
}

.company__usersTable__container table th{
    background-color:  rgb(125,163,60);
    padding: 0.8rem;
    color: white;
    position: sticky;
    top: 0;
}