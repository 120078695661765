.delete__modal__prompt__container{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 999;
}

.delete__modal__content{
    width: 290px;  
    height: 200px;
    background-color: white;
    padding: 0.8rem;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.delete__header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.delete__header .delete__icon{
    padding: 1rem;
    font-size: 6rem;
    color: red;
    flex: 1 1 60%;
    display: flex;
    align-items: center;
}

.delete__header label{
    flex: 1 1 40%;
}

.delete__actions{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0.2rem 0rem ;
}
