.farm-container{
    background-color: var(--bkg__whiteColor);
    display: flex;
    flex-direction: column;
    height: 100svh;
    flex: 1;
}

.farm-title{
    height:5svh;
    padding: 1rem;
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
}

.farm-search{
    position: relative;
    margin: 0 1.3rem;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.farm-card-container{
    display: flex;
    flex-wrap: wrap;
    margin: 1.3rem;
    position: relative;
}

.farm-card p{
    color: black;
}

.farm-card{
    display: flex;
    justify-content: center;
    margin: 10px 10px 0 10px;
    width: auto;
    height: 80px;
    background-color: var(--bkg__primary);
    align-items: center;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.farm-card a{
    
    text-decoration: none;
}

.farm-card h5{
    font-size: 1em;
    color: black;
}

.farm-card:hover{
    cursor: pointer;
}

@media only screen and (max-width: 768px){
    .farm-container{
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 100svh;
    }
    .farm-title h2{
        font-size: 18px;
    }
}