.contact-container{
    display: flex;
    height: 90vh;
    background-color:  rgb(246,246,246);
    flex-direction: column;
}

.contact-header{
    background-color: red;
    width: 100%;
    text-align: center;
    background: url('../../assets/bananafinal.webp');
    object-fit: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25vh;
}

.contact-header h1{
    color: white;
}

.contact-items{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-details{
    padding: 2rem;
}

.contact-details p{
    color: rgb(93, 93, 93);
    font-size: larger;
    text-align: start;
    line-height: 2.5rem;
}

@media only screen and (max-width: 768px){
    .contact-header h1{
        font-size: 22px;
    }

    .contact-details p{
        font-size: 16px;
    }

    .contact-header{
        height: 20vh;
    }
}