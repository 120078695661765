.block__modal__container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.block__modal__content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 250px;
    background-color: var(--bkg__primary);
    padding: 1rem 2rem;
    gap: 0.8rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4); 
}

.block__modal__content h3{
    padding: 0.5rem 0;
    width: 100%;
}

.farm__select__container{
    width: 100%;
}

.block__modal__fields{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.block__modal__actions{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
}