.legends__container{
    width: 65%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-radius: var(--bradius);
    box-shadow: var(--shdw);
    background-color: var(--bkg__primary);
}

.legends__container table{
    border-collapse: collapse;
    text-align: center;
    border: none;
}

.legend__table th, tr, td {
    border: none;
    padding: 0.6rem 0.5rem;
    font-size: 13px;
}

.legend__table tbody tr td{
    border: none;
}