.load__fetch{
    position: absolute;
    height: 100svh;
    width: 100svw;
    top: 0;
    z-index: 100;
    background-color: transparent;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}