.dashboard__header__container{
    margin: 1rem 2rem;
    background-color: var(--bkg__primary);
    padding: 1rem 1rem;
    display: flex;
    gap: 5px;
    box-shadow: var(--shdw);
}

.dashboard__header__item{
    flex: 1;
    background-color: var(--bkg__whiteColor);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20svh;
}

.dashboard__header__item h4{
    color: gray;
    font-size: 14px;
    font-weight: 100;
}