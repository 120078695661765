.chartview__container {
    background-color: var(--bkg__whiteColor);
    display: flex;
    flex-direction: column;
    height: 100svh;
    flex: 1;
    position: relative;
}

.chart__loading{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
}

.chart__contents{
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    flex: 1;
    flex-direction: column;
    overflow-x: scroll;
}


.chartview__content{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: scroll;
    flex-direction: row;
    gap: 2rem;
}

.chart__tabs__container{
    margin-bottom: 1px;
    border-bottom: 2px solid gray;
    width: 50%;
}