.farmview__menu__container{
    width: 100%;
    background-color: var(--bkg__primary);
    display: flex;
    flex-direction: column;
    height: 100svh;
    overflow-y: scroll;
}

.farmview__info{
    cursor: pointer;
}

.farmview__contents{
    flex: 1;
    width: 350px;
}

.farmview__index{
    padding: 1rem;
}

.farmview__index__title {
    font-size: 4px;
}

.index__container{
    display: flex;
}

.index__radio__control{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.farmview__nutrient__threshold{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.farmview__nutrient__threshold h4{
    font-weight: 500;
}

.nutrient__contents{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem 0;
}

.nutrient__info{
    color: red;
    font-size: 13px;
}

.farm__details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem 1rem;
    gap: 10px;
}

.farm__details label{ 
    font-size: 14px;
    color: var(--fontColor__secondary);
}

.farm__details h4{
    font-weight: 500;
}

.farm__row__details{
    display: flex;
    flex-direction: column;
    background-color: red;
}

.farm__row__details p{
    color: gray;
    font-size: 14px;
}

.farmview__nutrient_table tr th{
    font-size: 14px;
}

.farmview__nutrient__table__body{
    font-size: 12px;
    text-align: center;
}

.farmview__nutrient__table__body tr td{
    padding: 0.6rem 0rem;
}

.farmview__nutrient__table__body button{
    border: none;
    background-color: var(--primary__color);
    color: var(--bkg__whiteColor);
    border-radius: var(--bradius);
    cursor: pointer;
    padding: 0.6rem;
}

.farmview__nutrient__table__body button:hover{
    background-color: var(--hover);
}

.nutrient__header__container {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.nutrient__header__container h4{
    flex: 1;
}

.smartGridSearch__container{
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding: 1rem;
}

.smartGridSearch__loading{
    margin: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.nitrogen__summary_subheader{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
}

.nitrogen__body__summary{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.nitrogen__summary_subheader h5{
    flex: 1;
}

/* NUTRIENT RECOMM TABLE */

.nutrient__recommendation__container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.nutrient__recommendation__table{
    border-collapse: collapse;
    text-align: center;
}

.nutrient__recommendation__table tr td, tr th{
    border: none;
}

.nutrient__recommendation__table th{
    padding: 1rem;
}

@media only screen and (max-width: 768px){
    .farmview__menu__container{
        height: 100svh;
        overflow-y: scroll;
    }
}