.nutrient__container{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.nutrient__content{
    background-color: var(--bkg__primary);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    padding: 1rem 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    border-top: 3px solid var(--primary__color);
    position: relative;
}

.nutrient__header{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

.nutrient__header p{
    color: black;
    font-size: 12px;
}

.nutrient__header__title{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.nutrient__input{
    width: 100%;
    flex: 1;
    display: flex;
    gap: 10px;
    justify-content: center;
}

.nutrient__row__item{
    display: flex;
    flex-direction: column;
}

.nutrient__row__item label{
    font-size: 12px;
}

.nutrient__row__item input{
    padding: 0.65rem 0.75rem;
    width: 50px;
    font-size: 14px;
}

.nutrient__row__item input:focus{
    outline: none;
}

.nutrient__actions{
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

/* .farmview__nutrient__threshold__container{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
} */

.no__datasets__available{
    height: 200px;
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.no__dataset__label{
    font-size: 13px;
    color: gray;
}

.farmview__nutrient__header{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.grid__input__container{
    display: flex;
    gap: 0.5rem;
    margin-top: 2rem;
    flex-direction: column;
}

.grid__input__field{
    display: flex;
    gap: 0.8rem;
    flex-direction: column;
}

::-webkit-scrollbar {
    width: 5px;
}

.grid__display::-webkit-scrollbar-thumb {
    background: #888;
}

.grid__display{
    max-height: 40svh;
    overflow-y: scroll;
    width: 100%;
}

.grid__display table{
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

.grid__display table th{
    background-color: white;
    color: black;
    font-size: 11px;
    padding: 1rem;
    position: sticky;
    top: 0;
    z-index: 999;
}

.grid__display tbody tr td{
    font-size: 12px;
    padding: 0.4rem;
    z-index: 10;
}

.grid__display table tr{
    scroll-snap-align: start;
}

.vegetation__container {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
}

.additional__grid__container{
    display: flex;
    gap: 0.8rem;
}

.selectInputType__container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}