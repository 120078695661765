.service-container{
    background-color: rgb(246,246,246);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-x: hidden;
}

.service-title{
    text-align: center;
    background: url('../../assets/banana-serv.webp');
    object-fit: cover;
    background-position: center;
    height: 25vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.service-title h1{
    color: white;
}

.card-container{
    padding: 3rem;
    display: flex;
    flex-wrap: wrap;
    gap: 5rem;
    justify-content: center;
    align-items: center;
}

.card-service{
    display: flex;
    gap: 1rem;
    flex-wrap: nowrap;
    flex-direction: column;
    width: 300px;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    border-radius: 10px;
}

.card-service img{
    width: 300px;
    object-fit: scale-down;
    border-radius: 10px;
}

.card-content p{
    color: black;
    text-align: start;
}


@media only screen and (max-width: 768px){
    .service-title{
        height: 20vh;
    }

    .card-container{
        gap:0.5rem;
    }

    .service-title h1{
        font-size: 22px;
    }

    .card-content h2{
        font-size: 17px;
    }

    .card-content p{
        font-size: 15px;
    }
}