.weather__data__container{
    top: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 1000;
}

.weather__display{
    height: 50svh;
    background-color: var(--bkg__primary);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    border-top: 3px solid var(--primary__color);
    width: 500px;
}

.weather__body{
    flex: 1;
    overflow-y: scroll;
}

.weather__header{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0.5rem 0 1rem 0;
}

.weather__header h3{
    flex: 1;
}

.weather__actions {
    display: flex;
    justify-content: flex-end;
}

/* .weather__header h3{
    flex: 1;
} */

.display__no__data{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: gray;
    font-size: 13px;
}

.weather__body::-webkit-scrollbar-thumb {
    background: #888;
}

.weather__body table{
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

.weather__table__display th{
    background-color: var(--primary__color);
    padding: 0.6rem;
    color: white;
    position: sticky;
    top: 0;
    z-index: 1;
    font-size: 13px;
}

.weather__table__display tbody tr:nth-of-type(even) {
    background-color: #F2F2F2;
}

.weather__table__display tbody tr td {
    padding: 0.4rem;
    font-size: 13px;
}