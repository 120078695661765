.role__container{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.role__content{
    border-top: 3px solid var(--primary__color);
    width: 250px;
    background-color: var(--bkg__primary);
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    gap: 1rem;
    box-shadow: var(--shdw);
    justify-content: flex-start;
    align-items: flex-start;
}

.role__content h4{
    padding: 0.5rem 0 0.8rem 0;
}

.role__item{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.role__actions{
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    gap: 5px;
}

.role__actions button{
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0.8rem;
    border-radius: var(--bradius);
}

.role__actions #role__update{
    background-color: var(--primary__color);
    color: var(--fontColor__primary);
}

@media only screen and (max-width: 768px) {
    .role__content{
        width: 220px;
        color: black;
    }
}