.weather-container{
    height: 100vh;
    background-color: rgb(236,236,236);
    display: flex;
    flex-direction: column;
    flex: 1;
}

.weather-container h3{
    padding: 1rem 3.2rem;
    font-size: x-large;
}

.weather-search-loc{
    display: flex;
    flex-direction: column;
}

.weather-search-loc input{
    padding: 1.5rem;
    font-size: large;
    border: none;
    border-bottom: solid 1px gray;
}


.weather-container h3 i{
    padding: 0 0.2rem;
}

.weather-items{
    display: flex;
    width: 250px;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    padding: 1rem;
    gap: 2rem;
    flex-wrap: wrap;
}

.weather-card-title h4{
    text-align: start;
    font-size: larger;
}

.weather-card{
    background-color: white;
    padding: 1rem 2rem;
    border-radius: 10px;
    line-height: 2rem;
    width: 250px;
    margin-left: 2.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}


.weather__section{
    margin: 0.5rem 1rem 1rem 1rem;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

/* weather */

.weather__section h3{
    padding: 1rem;
}

.weather__Section__title{
    display: flex;
    align-items: center;
    background-color: rgb(125,163,60);
    border-radius: 10px 10px 0 0;
}

.weather__Section__title a{
    padding: 1rem;
    font-size: 14px;
}

.weather__Section__title h3{
    color: white;
    flex: 1;
    font-size: 18px;
}

.db__weather_forecast_title{
    display: flex;
    color: white;
}

.db__weather_forecast_title h3{
    flex: 1;
    font-size: 18px;
}

.db__weather_forecast a{
    font-size: 13px;
    cursor: pointer;
    text-decoration:  none;
    padding: 10px;
    display: flex;
    align-items: center;
    color: rgb(114,137,218);
}

.db__weather_forecast a:hover{
    color: green;
}



.db__weather_current{
    display: flex;
    background-color: rgb(66,69,73);
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;   
    margin: 0.5rem 0.5rem 0 0.5rem;
}

.db__current__left{
    flex:1;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    color: white;
}



.db__current__left h1{
    font-size: 3rem;
    padding: 0 1rem;
}

.db__current__right{
    flex: 1;
    padding:  0 1rem;
    display: flex;
    flex-direction: column;
}

.db__current__right p{
    color: white;
}

.db__right__contents p{
    color: white;
    padding: 0.2rem 1rem;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.db__right__status{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
}

.db__weather_forecast{
    background-color: rgb(66,69,73);
    padding: 0.5rem 0.5rem 1rem 0.5rem;
    margin: 0rem 0.5rem 0.5rem 0.5rem;
}



.db__weather__card_contain{
    display: flex;
    gap: 1rem;
    justify-content: space-around;
    flex-wrap: wrap;
}

.db__weather__card{
    display: flex; 
    width: 26%;
    padding: 1.5rem;
    background-color:white;
    border-radius: 10px;
    color: gray;
    gap: 0.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.db__weather__content{
    display: flex;
    gap: 0.3rem;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    font-size: 14px;
}

.db__weather__title{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.db__weather__title img{
    width: 70px;
}

.db__weather__title span{
    font-size: 16px;
}

.weather-title{
    height:5svh;
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    gap: 10px;
}

.weather-title i{
    padding: 1rem;
    cursor: pointer;
}