:root{
    --bkg__whiteColor:  rgb(236,236,236);
    --bkg__primary: white;
    --bkg__secondary: #313638;
    --primary__color: rgb(125,163,60);
    --secondary__color: green;
    --fontColor__primary: white;
    --fontColor__secondary: #313638;
    --bradius: 10px;
    --hover:  rgb(104, 135, 52);
    --bkg__edit: rgb(78, 49, 243);
    --shdw: 0 2px 4px rgba(0, 0, 0, 0.2);
    --hover__white: #d0d0d0;
    --gray: #777777;
    --no_background: none;
    --red: 	rgb(255,0,0);
    --orange: rgb(242, 121, 53);
    --active-bkg__primary: #000000;
    --active-bkg__primary-hover: #333333;
}


*{
    margin: 0;
    padding: 0;
}