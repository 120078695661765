.dashboard__container{
    background-color: var(--bkg__whiteColor);
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100svh;
    overflow-y: scroll;
}

.page__title__db{
    height:5svh;
    padding: 1rem;
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
}

.dashboard__contents{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
    overflow-y: scroll;
}

.farm__section_1{
    margin: 0.5rem 0.5rem 0rem 1rem;
    border-radius: 5px;
    
}

.farm__content__db{
    background-color: var(--bkg__primary);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding-bottom: 1rem;
    flex: 1;
    margin: 0 1rem 0 1rem;
    border-radius: 5px;
}


.farm__content__db h3{
    padding: 1rem;
    border-radius: 5px 5px 0 0;
    background-color: rgb(125,163,60);
    color: var(--fontColor__primary);
}

.farm__select{
    padding:  1rem;
    display: flex;
    gap: 5px;
    justify-content: space-evenly;
}

.farm__select button{
    background-color: green;
    border: none;
    color: white;
    cursor: pointer;
    padding: 0.5rem ;
    border-radius: 5px;
}

.farm__select button:hover{
    color: black;
}

.farm__select p{
    color: black;
}

.farm__select select{
    border: none;
    border-bottom: 1px solid gray;
    background: transparent;
    padding: 0rem 1rem;
    color: black;
}

.farm__select select:focus{
    outline: none;
}

.farm__content{
    display: flex;
    flex-direction: row;
    padding: 0.5rem;
    
}

.farm__weekly__data{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.db__search__farm{
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}

.db__search__farm input{
    width: 60px;
    padding: 0.5rem;
}

@media only screen and (max-width: 768px){
    .dashboard__container{
        height: 100svh;
        display: flex;
        flex-direction: column;
    }

    .dashboard__contents{
        display: flex;
        flex-direction: column;
    }

    .farm__select{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .farm__content{
        flex: 1;
    }

    .page__title__db h2{
        font-size: 18px;
    }
}