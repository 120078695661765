.nutrient__item__container{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    z-index: 100;
    padding: 1rem;
    width: 100%;
}

.nutrient__item__content {
    display: flex;
    flex-direction: column;
    background-color: var(--bkg__primary);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    padding: 1rem;
    gap: 1rem;
    width: 400px;
    border-top: 3px solid var(--primary__color);
}

.nutrient__item__content h4{
    padding: 0.5rem 0;
}

.update__grid__input__field{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.update__vegetation__container, .update__additional__grid__container{
    display: flex;
    justify-content: flex-start;
    gap: 0.5rem;
    align-items: flex-start;
}   

.nutrient__update__actions{
    display: flex;
    justify-content: flex-end;
}