
.account__fields{
    background-color: var(--bkg__primary);
    display: flex;
    flex-direction: column;
    box-shadow: var(--shdw);
    border-top: 3px solid var(--primary__color);
    padding: 2rem;
    gap: 2rem;
}

.title__header{
    text-align: start;
}

.account__fields form{
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    width: 280px;
}

.form__fields__items{
    display: flex;
    flex-wrap: wrap;
    gap: 1.2rem;
    width: 100%;
}

.form__row{
    display: flex;
    width: 100%;
}

.form__buttons{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 0.3rem;
    margin: 1rem 0 0.5rem 0;
}


@media only screen and (max-width: 768px){
    .account__fields form{
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        width: 250px;
    }
    .form__row{
        width:100%;
    }

    .form__fields__items{
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
    }

    .form__buttons button{
        font-size: 13px;
    }
}

