.db__edit__farm__container{
    flex: 1;
    height: 100dvh;
    position: relative;
}

.edit__farm__title{
    height:5svh;
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    gap: 10px;
}

.edit__farm__title h2{
    flex: 1;
}

.edit__farm__title i{
    padding: 1rem;
}

/* edit body content */

.modal__farm_body{
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
}

.selected__inputs__modal{
    margin-top: 0.5rem;
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    flex-wrap: wrap;
}

.selected__inputs__modal .farm__id{
    display: none;
}

.selected__input__row{
    width: 200px;
}



.modal__farm__actions{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    background-color: var(--bkg__primary);
    padding: 1rem 0;
}


.input__fields__db{
    display: flex;
    background-color: var(--bkg__primary);
    justify-content: flex-start;
    padding-top: 0.6rem;
}


.modal__raster__details{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.modal__raster__details p{
    font-size: 13.5px;
}

.modal__left{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}


.modal__right{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.db__farm__body_container{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    margin: 2rem;
}

.company__farm__title{
    padding: 0.5rem 0;
    background-color: var(--bkg__primary);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.7rem;
}

.company__farm__title h4{
    flex: 1;
}

.modal__raster__container{
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
}

.modal__raster__container button:hover{
    background-color: var(--hover);
}

.farm__edit__loader{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit__input_container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    background-color: var(--bkg__primary);
    border-top: 3px solid var(--primary__color);
    width: 800px;
}

.sensors__modal__container{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex: 1;
}

@media only screen and (max-width: 768px){

    .selected__inputs__modal{
        width: 280px;
    }
    
    .db__edit__farm__container{
        height: 100svh;
    }

    .modal__farm_body{
        margin-top: 1rem;
    }
}