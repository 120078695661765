.navbar-container{
    background-color: rgb(255,255,255);
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    align-items: center;
    border-bottom: solid 0.5px rgb(208,208,208);
}



.logo-container img{
    width: 70px;
    padding: 0.5rem 2rem 0 6rem;
}

.logo-container h1{
    padding:  1rem 2rem 1rem 4rem;
}

.logo-container h1 a{
    text-decoration: none;
    color: gray;
}

.navbar-ul ul{
    display: flex;
    gap: 2rem;
    list-style: none;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 5rem;
    font-size: larger;
}


.navbar-ul ul li a{
    text-decoration: none;
    color: gray;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 1rem 0 1rem;
}

.navbar-ul ul li a:hover{
    color: rgb(37, 215, 37);
}

.navbar-ul ul{
    display: flex;
}


.nav-btn-contain{
    display: none;
}

@media only screen and (max-width: 768px) {
    .navbar-container{
        background-color: rgb(255,255,255);
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        align-items: center;
    }

    .logo-container h1{
        padding:  1rem;
        color: gray;
    }

    .logo-container h1{
        font-size: 1.5rem;
    }

    .nav-btn-contain{
        display: flex;
        padding: 1rem;
    }

    .nav-btn-contain button{
        outline: none;
        border: none;
        background: transparent;
        font-size: 1.5rem;
        cursor: pointer;
    }

    .navbar-ul ul{
        display: none;
    }

    .navbar-ul #hidden{
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 0;
        top: 7.6vh;
        background-color: rgb(246,246,246);
        height: 60svh;
        max-height: 60svh;
        flex: 1;
        width: 95%;
        padding: 1.1rem;
    }

    .navbar-ul #hidden a{
        color: black;
        font-size: 20px;
    }

    .navbar-ul #hidden a:hover{
        color: rgb(37, 215, 37);
    }

    .navbar-ul #hidden .launch-btn{
        padding: 0.5rem 2rem;
    }

    .navbar-ul #hidden .launch-btn:hover{
        color: black;
    }

}