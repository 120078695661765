.home-container{
    height: 90svh;
    display: flex;
    justify-content: space-evenly;
    gap: 2rem;
    align-items: center;
}


video{
    position: absolute;
    width: 100%;
    height: 91%;
    object-fit: cover;
    z-index: -1;
}

.home-container img{
    position: absolute;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    opacity: 5;
    width: 100%;
}

.partner-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.partner-container h2{
    margin-top: 2.2rem;
    padding: 0 1rem 1rem 1rem;
}

.img-container{
    display: flex;
    flex-wrap: wrap;
    gap: 5rem;
    padding: 2rem;
}

.home-content{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home-items{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
}

.home-items h1, p{
    padding: 10px 0 15px 0;
    color: white;
    text-align: center;
}

.home-items a{
    padding: 10px 0 15px 0;
    color: white;
    text-align: center;
}


.home-items h1{
    font-size: 6rem ;
    font-weight: 900;
    margin: 0 2rem;
}

.home-items p{
    padding: 0 3rem 2rem 0;
    font-size: large;
}

.home-items a{
    text-decoration: none;
    font-size: larger;
    border: 1px solid white;
    padding: 1rem;
    border-radius: 5px;
    width: 15%;
    text-align: center;
}

.home-items a:hover{
    background-color: green;
    border: 1px solid green;
}

.img-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-container img{
    width: 120px;
    object-fit: cover;  
} 



@media only screen and (max-width: 768px) {
 

    .home-items h1{
        padding: 10px 0 5px 0;
        color: white;
        text-align: center;
        font-size: 2.5rem;
    }

    .home-items p{
        padding: 0.8rem 1rem;
        color: white;
        text-align: center;
        font-size:  1.3rem;
    }

    .home-items a{
        text-decoration: none;
        font-size: medium;
        border: 1px solid white;
        padding: 1rem;
        border-radius: 5px;
        width: 30%;
        text-align: center; 
        margin-top: 5px;
    }

    .home-container{
        height: 90svh;
        display: flex;
        justify-content: space-evenly;
        gap: 2rem;
        align-items: center;
    }

    .home-container img{
        position: absolute;
        height: 95%;
        object-fit: cover;
        z-index: -1;
        opacity: 5;
        width: 100%;
    }

    .partner-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem;
        margin-top: 1rem;
    }

    .img-container{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .img-container img{
        width: 120px;
        object-fit: cover;  
        flex-wrap: wrap;
    } 

  
  }