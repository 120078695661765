
.list__user__container{
    margin:  1rem 2.5rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.list__users{
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: relative;
    border-bottom: 5px solid rgb(125,163,60);
}


.list__users table tr td{
    padding: 0.6rem;
}

.list__users table th{
    background-color:  rgb(125,163,60);
    padding: 1rem;
    color: white;
    position: sticky;
    top: 0;
}

.list__users table{
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

.list__user__container table tbody tr:nth-of-type(even){
    background-color:#F2F2F2;
}

.tb__actions button{
    outline: none;
    border: none;
    padding: 0.6rem;
    color: white;
    width: 100px;
    border-radius: 10px;
    background-color: transparent;
    background-color: var(--primary__color);
}

.tb__actions button:hover{
    cursor: pointer;
}

.btn__update__account{
    background-color: blue;
}

.user__pagenated__buttons{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 0.5rem;
}

.user__pagenated__buttons button{
    cursor: pointer;
    border: none;
    background: transparent;
}

.user__search{
    position: relative;
    flex-wrap: wrap;
}

.user__search i{
    position: absolute;
    top: 15px;
    left: 10px;
}

#user__search__input{
    border: 0;
    background: transparent;
    font-size: medium;
    border-bottom: solid 1px black;
    width: 250px;
    padding: 0.9rem 0.9rem 0.9rem 2rem;
    font-size: 15px;
}

#user__search__input:focus{
    outline: none;
}

@media only screen and (max-width: 768px){
    .list__user__container{
        width: 100%;
    }
    .list__users{
        max-height: 100svh;
    }

    .tb__actions button{
        outline: none;
        border: none;
        padding: 0.5rem;
        color: white;
        width: 50px;
        border-radius: 10px;
    }
}