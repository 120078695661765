.login-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100svh;
    background-color: rgb(236,236,236);
}

.login-img{
    flex: 1;
    background: url('../../assets/bansot_logo.png');
    object-fit: contain;
    background-position: right center;
    height: 100vh;
    background-size: 600px;
    background-color: rgb(236,236,236);
    background-repeat: no-repeat;
}


.login-form-container{
    flex: 1;
}

.login-form-container form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-fields{
    background-color: white;
    padding: 2rem;
    line-height: 50px;
    width: 250px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-top: 3px solid rgb(125,163,60);
    position: relative;
    display: flex;
    flex-direction: column;
}

.login__title{
    border-bottom: 1px solid black;
}

.login__title h2{
    text-align: center;
    color: rgb(125,163,60);
    font-size: 2em;
}

.login__description p{
    color: black;
    padding: 0;
    line-height: 25px;
    text-align: center;
    font-size: 13px;
    padding: 0.8rem 1rem 1rem 1rem;
    margin: 0.3rem;
}

.login-fields a{
    margin-top: 1rem;
    text-decoration: none;
    background-color: green;
    color: white;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.input-fields{
    display: flex;
    gap: 0.8rem;
    flex-direction: column;
}

.login__action{
    margin-top: 10px;
}



@media only screen and (max-width: 768px){
    .login-img{
        display: none;
    }

    .login-fields{
        width: 230px;
    }

    .login-fields input{
        border: None;
        width: 90%;
        border-bottom: 1px solid black;
        font-size: 16px;
    }

}