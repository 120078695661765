.sidebar-container{
    background-color:white;
    width: 18%;
    display: flex;
    flex-direction: column;
    box-shadow: 1px 6px 20px #737171;
    z-index: 200;
}

.sidebar__wrapper{
    position: sticky;
    top: 0;
    left: 0;
}

.sidebar-logo-container{
    width: 100%;
    border-bottom: solid 1px rgb(88, 88, 88);
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.sidebar-logo-container img{
    display: block;
    width: 60px;
    padding: 0.5rem 1rem; 
}

.sidebar-logo-container h1{
    color: rgb(163,161,163);
    flex: 1;
    font-size: 25px;
}

.sidebar-logo-container i{
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
}

.sidebar-container-items{
    height: auto;
    width: 100%;
}

.sidebar-container-items ul{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.sidebar-container-items .row-items{
    width: 100%;
    height: 50px;
    list-style-type: none;
}

.sidebar-container .row-items a{
    text-decoration: none;
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    margin: 0.5rem;
    border-radius: 10px;
    color: #313638;
}

.sidebar-container .row-items a #row-icon{
    flex: 10%;
    display: grid;
    place-items: center;
}

.sidebar-container .row-items a #row-icon i{
    padding: 0;
    margin: 0;
}

.sidebar-container .row-items a #row-title{
    flex: 80%;
    padding-left: 13px;
}

.sidebar-container .row-items a:hover {
    background-color:rgb(236,236,236);
    color: green;
    cursor: pointer;
}

.sidebar-container #active{
    background-color: rgb(125, 163, 60);
    color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 768px){
    .sidebar-container{
        position: absolute;
        top: 0;
        left: 0;
        height: 100svh;
        display: none;
    }

    .sidebar-logo-container h1{
        display: none;
    }

    .sidebar-container .row-items a{
        padding: 1.5rem;
    }
    
    .sidebar-container .row-items a{
        text-decoration: none;
        display: flex;
        padding: 1.2rem;
        align-items: center;
        justify-content: center;
        margin: 0.5rem;
        border-radius: 10px;
        color: black;
    }

    .sidebar-container-items ul{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}