.report__header__container{
    margin: 0 2rem;
    display: flex;
    gap: 2rem;
}

/* SEARCH CSS */

.report__search__container{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    flex-direction: column;
    padding: 2rem 1.5rem;
    background-color: var(--bkg__primary);
    box-shadow: var(--shdw);
}

.report__select__location__container{
    width: 100%;
    display: flex;
    gap:  1rem;
    flex: 1;
    flex-direction: column;
}

.report__select__params{
    display: flex;
    gap: 1rem;
}

.report__header__actions{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 0.5rem;
}

/* LEGEND CSS */

.report__legend__container{
    background-color: var(--bkg__primary);
    box-shadow: var(--shdw);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.legends__item{
    display: flex;
    flex-direction:row;
    gap: 0.6rem;
    justify-content: flex-start;
    align-items: center;
}