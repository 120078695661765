.dashboard__body__container{
    margin: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.dashboard__body__item{
    background-color: var(--bkg__primary);
    box-shadow: var(--shdw);
    display: flex;
}

.dashoard__col__item{
    flex: 1;
    padding: 1rem;
    display: flex;
    gap: 1rem;
}

.dashoard__col__item__title{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.dashboard__col__actions{
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.dashoard__col__item h4{
    text-align: center;
}