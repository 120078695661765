.area__percentage__container{
    flex: 1;
    display: flex;
    gap: 0.2rem
}

.area__percentage__chart{
    height: 150px;
    width: 240px;
}

.area__display__chart{
    padding-top: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-size: 13px;
    flex-direction: column;
    color: gray;
}