
.sensors__container{
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10000;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.sensors__box{
    display: flex;
    flex-direction: column;
    background-color:var(--bkg__primary);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: var(--bradius);
    gap: 0.5rem;
    padding: 1rem;
}   

.soil__sensors__icons, .weather__data__icons{
    display: flex;
    flex-direction: column;
    font-size: 13px;;
    padding: 0.2rem;  
    gap: 0.3rem;
    /* background-color:var(--bkg__primary);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: var(--bradius); */
}

.sensor__items{
    display: flex;
    flex-direction: row;
    font-size: 13px;
    gap: 0.5rem;
}

.commulative__actions{
    display: flex;
    gap: 0.2rem;
}

.sensor__header{
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
}

.sensor__header h4{
    flex:1;
}