.map__vegetation__contaier{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.map__vegetation__content{
    width: 800px;
    height: 500px;
    background-color: var(--bkg__primary);
    box-shadow: var(--shdw);
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 0.5rem;
}

.map__vegetation__action{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

/* MAP NAVIGATION HEADER  */

.map__vegetation__header{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem;
    border-bottom: 1px solid gray;
}

.vegetation__body{
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.vegetation__content{
    background-color: var(--bkg__whiteColor);
    width: 250px;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    box-shadow: var(--shdw);
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.3rem;
    font-size: 13px;
}

.vegetation__content p{
    padding: 0;
    margin: 0;
    color: black;
}

.vegetation__item{
    display: flex;
    width: 100%;
}

.vegetation__item__mean__index, .vegetation__item__mean__nitrogen{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
}

/* TAB MENU HEADER CSS */

.tab__menuHeader__container{
    margin: 1rem 0.3rem 0 0.3rem;
    display: flex;
    gap: 1rem;
}

/* VEGETATION TABLE CSS */

.mag__vegetation__table{
    flex: 1;
    margin: 0.3rem 0.3rem 0 0.3rem;
    overflow-y: scroll;
    border-bottom: 0.5px solid gray;
}

.mag__vegetation__table table{
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

.mag__vegetation__table table th{
    background-color: var(--primary__color);
    padding: 0.6rem 0.5rem;
    color: white;
    position: sticky;
    top: 0;
    font-size: 13px;
}

.mag__vegetation__table table tbody tr:nth-of-type(even){
    background-color:#F2F2F2;
}

.mag__vegetation__table table td{
    font-size: 13px;
    text-transform: capitalize;
    padding: 0.8rem;
}