.fv__menu__blockChart__container{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100000;
    align-items: center;    
    display: flex;
    justify-content: center;
}

.gridChart__modal__cotaniner{
    width: 700px;
    background-color: var(--bkg__primary);
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4); 
}

.grid__chart__body {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 0.5rem;
}

.grid__header {
    padding: 0.8rem;
    display: flex;
    align-items: center;
    border-bottom: 2px solid gray;
}

.grid__header h3 {
    flex: 1;
}

.grid__actions {
    display: flex;
    justify-content: flex-end;
}

.grid__block__table{
    width: 100%;
    padding: 1rem;
}

.grid__chart__contents{
    padding: 1rem;
}