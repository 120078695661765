.report__content__container{
    background-color: var(--bkg__primary);
    box-shadow: var(--shdw);
    margin: 1.5rem 2rem;;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* overflow-y: auto; */
    flex: 1;
    position: relative;
}

.report__content__loader{
    background-color: var(--bkg__primary);
    box-shadow: var(--shdw);
    margin: 1.5rem 2rem;;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
    position: relative;
    justify-content: center;
    align-items: center;
}

.report__content__actions{
    display: flex;
    padding: 0.6rem 1rem;
    border-bottom: 1px solid gray;
    flex-shrink: 0;
}

.report__tabs__container{
    flex: 1;
}

.report__table__panel__container{
}


/* REPORT TABLE CSS */

.report__table__container{
    width: 100%;
    text-align: center;
    overflow-y: scroll;
    max-height: 450px; /* Set the max height for the scrollable area */
}

.report__table__container table{
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

.report__table__container table, th, td {
    border: 1px solid rgb(149, 147, 147);
}

.report__table__container table th{
    padding: 0.8rem 0.5rem;
    font-size: 13px;
    background-color: #F2F2F2;
    position: sticky; /* Keep headers visible on scroll */
    top: 0;
    z-index: 5;

}

.report__table__container table td{
    font-size: 13px;
    text-transform: capitalize;
    padding: 0.8rem;
}

