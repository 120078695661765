.raster__upload__container{
    position: absolute;
    background-color: var(--bkg__whiteColor);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    height: 100%;
    width: 100%;
    top: 0;
    background-color: transparent;
}

.raster__input{
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    width: 350px;
    border-top: 3px solid var(--primary__color);
}  

.raster__input h3{
    padding: 1rem 1rem 0.5rem 1rem;
}

.form__raster{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    position: relative;
    gap: 1rem;
}

.form__raster .allowed__extension{
    text-align: center;
    font-size: 10px;
    color: blue;
}

.raster__label{
    margin: 0 0.5rem;
    display: flex;
    padding: 0.8rem 0;
}

.upload__holder{
    position: absolute;
    bottom: 0.6rem;
}

.raster__label label{
    width: 85%;
    overflow-y: hidden;
    overflow: hidden;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.raster__label i{
    flex: 1;
    text-align: center;
    display: flex;  
    align-items: center;
    justify-content: center;
    color: red;
}

.raster__label i:hover{
    cursor: pointer;
}

.raster__buttons{
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    padding: 0.5rem 0;
}

.weather__type__container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.upload__raster__container{
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.upload__shapefile__container{
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.list__file__container{
    background-color: var(--bkg__whiteColor);
    display: flex;
    justify-content: space-between;
    border-radius: 6px;
}

.file__extension__name{
    padding: 0.8rem;
    border-radius: var(--bradius);
    font-size: 11px;
}
