.raster__list__container{
    width: 100%;
    height: 50vh;
}

.raster__container__table::-webkit-scrollbar-thumb {
    background: #888;
}

.raster__container__table{
    max-height: 40vh;
    overflow-y: scroll;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-bottom: 5px solid rgb(125, 163, 60);
}

.rasters__table__display{
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    
}

.rasters__table__display th {
    background-color: var(--primary__color);
    padding: 1rem;
    color: white;
    position: sticky;
    top: 0;
    z-index: 2;
}

.rasters__table__display td {
    font-size: 14px;
    text-transform: capitalize;
}

.rasters__table__display tbody tr:nth-of-type(even) {
    background-color: #F2F2F2;
}

.rasters__table__display tbody tr td {
    /* padding: 0.5rem 0.8rem; */
    padding: 0.2rem;
}

.raster__tb__actions {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
}

.raster__records{
    color: var(--bkg__secondary);
    text-align: center;
    width: 100%;
}

.raster__header{
    display: flex;
    align-items: center;
    padding: 0.8rem 0;
}

.raster__loading_spinners{
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search__gridraster__container{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}


/* APPLICATOR MODAL CSS */

.assign__applicator__container{
    top: 0;
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.assign__applicator__header{
    display: flex;
    align-items: center;
    justify-content: center;
}

.assign__applicator__header h4{
    flex: 1;
}

.assign__applicator__content{
    background-color: var(--bkg__primary);
    box-shadow: var(--shdw);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 800px;
}

.assign__applicator__selector{
    display: flex;
    width: 300px;
    gap: 1rem;
    flex-direction: column;
    padding: 1rem;
}

.assign__actions{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

/* ASSIGN APPLICATOR TABLE */

.assign__applicator__table__container{
    width: 100%;
}

.assign__applicator__table__loader{
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.applicator__table{
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

.applicator__table th{
    padding: 1rem;
    font-size: 13px;
}

.applicator__table tbody td{
    padding: 1rem;
    font-size: 12px;
}

/* INPUT MODAL */
.applicator__input__modal__container{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.applicator__input__content{
    display: flex;
    background-color: white;
    padding: 0.8rem;
    box-shadow: var(--shdw);
    flex-direction: column;
}

.applicator__assigned__actions{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
    padding: 0 1rem 1rem 1rem;
    
}