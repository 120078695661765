.chartview__container__all{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 300px;
    margin: 0 2rem;
}

.chartview__table__container__parent{
    overflow-y: scroll;
    border-bottom: 5px solid rgb(125, 163, 60);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);;
    /* width: 350px; */
    width: 100%;
}

.chartview__table__container{
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

.chartview__table__container__parent::-webkit-scrollbar-thumb {
    background: #888;
}
 
.chartview__table__container th {
    background-color: var(--primary__color);
    padding: 0.8rem;
    color: white;
    position: sticky;
    top: 0;
    z-index: 2;
} 


.chartview__table__container td {
    font-size: 13px;
    text-transform: capitalize;
    padding: 0.4rem 0;
}

.chartview__table__container tbody tr:nth-of-type(even) {
    background-color: #F2F2F2;
} 

.search__grid__container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    flex-direction: column;
}

.search__grid__container h4{
    flex: 1;
}