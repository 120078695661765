.intervention__content__container{
    flex: 1;
    margin: 0 2rem;
    overflow-y: scroll;     
}

.intervention__content{
    flex: 1;
    margin-bottom: 2rem;
    background-color: var(--bkg__primary);
    box-shadow: var(--shdw);
}

.intervention__application__container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
}

/* APPLICATION TABLE CSS */

.intervention__application__table{
    border-collapse: collapse;
    width: 100%;
    text-align: center;
}

.intervention__application__table tr th{
    padding: 0.8rem;
}

.intervention__application__table tr td{
    border: none;
}

.intervention__application__table thead tr th{
    border-bottom: 1px solid gray;
    padding: 1rem;
}

.intervention__application__header{
    display: flex;
    justify-content: center;
    align-items: center;
}

.intervention__application__header h3{
    flex: 1;
}

/* INTERVENTION ACTIVITY TABLE */

.intervention__application__activity__header{
    padding: 1.4rem;
}

.intervention__activity__table__container{
    padding: 0 1rem 1rem 1rem;
}


.intervention__activity__table{
    margin-top: 1rem;
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    padding: 1rem;
}

.intervention__activity__table thead tr th{
    padding: 1rem;
    border-bottom: 1px solid gray;
}

.intervention__activity__table tbody tr td{
    border: none;
    padding: 1.2rem 0;
}

