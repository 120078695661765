*{
  font-family: 'Open Sans', sans-serif;
  font-family: 'Poppins', sans-serif;
}

.app-container{
  display: flex;
  flex-direction: column;
  width: 100%;
}


.main-page-content{
  width: 100%;
} 

.leaflet-container{
  width: 100%;
  height: 90vh;
}

