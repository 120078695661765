.account__container{
    height: 100svh;
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
    background-color: var(--bkg__whiteColor );
}

.page__title__am{
    height:5svh;
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    gap: 10px;
    position: relative;
}

.page__title__am i{
    padding: 1rem;
    cursor: pointer;
}


.modal__account__container{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 100;
}


.account__body__content{
    display: flex;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
    position: relative;
}

.manager__container{
    flex: 1;
    margin: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.manager__table__actions{
    width: 70%;
    display: flex;
    justify-content: flex-end;
}

.manager__table__actions button{
    
    cursor: pointer;
    border: none;
    background: transparent;
}

@media only screen and (max-width: 768px){
    .page__title__am h2{
        font-size: 18px;
    }
}