.main-app-layout{
    display: flex;
    flex-direction: row;
    background-color: rgb(246,246,246);
}


.user__info__db{
    position: absolute;
    top: 1.8%;
    right: 0;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
}

/* .user__info__db p{
    color: black;
    text-transform: capitalize;
    display: flex;
    padding-top: 1rem;
    flex: 1;
    padding-left: 1.5rem;
} */

/* .user__info__db button{
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0 1.2rem;
    color: black;
    flex: 1;
    display: flex;
    justify-self: center;
    align-items: center;
} */

.account__drop__container{
    position: absolute;
    top: 6%;
    right: 0%;
    z-index: 9;
    width: 250px;
    display: flex;
    height: 150px;
}

.drop__contents{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    align-items:center;
}

.row__items{
    width: 180px;
}

.drop__contents li{
    list-style-type: none;
    display: flex;
    background-color:white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.drop__contents li:hover {
    background-color: rgb(246, 246, 246);
}

@media only screen and (max-width: 768px){
    .main-app-layout{
        height: 100svh;
        position: relative;
    }

    .user__info__db{
        position: absolute;
        top: 3%;
        right: 0;
        z-index: 9;
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        width: 15%;
    }

    .user__info__db p{
        display: none;
    }

    .account__drop__container{
        position: absolute;
        top: 7%;
        z-index: 9;
        display: flex;
        width: 200px;
    }
}
